$FIRE_NUM: 12;

.title-text {
  .mobile > & {
    transform: rotate(90deg);
    transform-origin: left bottom;
    font-size: 5rem;
    top: 0;
    left: 5%;

    background-size: auto 35rem;
  }

  color: #707070;
  font-family: 'Poiret One', cursive;
  font-weight: 300;
  font-size: 4rem;

  position: fixed;
  top: 20%;
  left: 10%;

  cursor: default;
  user-select: none;

  background: linear-gradient(#707070, #707070 50%, #df6969 80%, #e21d1d 100%);
  background-position: 0 0;
  background-size: auto 30rem;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  transition-property: background-position;
  transition-duration: 10s;

  &::after {
    position: absolute;
    top: 5rem;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: #707070;
    transform: scale(0, 1);
    transform-origin: left top;
    transition: transform 0.5s;

    .mobile & {
      top: 6.2rem;
    }
  }

  &:hover {
    background-position: 0 -25rem;

    @for $i from 0 through $FIRE_NUM {
      .fire-container_#{$i} {
        transform: scale(1);
        transition-property: transform;
        transition-delay: #{$i * 0.1}s;
        transition-duration: 1s;
      }
    }

    &::after {
      transform: scale(1, 1);
    }
  }
}

.fires {
  display: flex;
  margin-top: 1rem;

  & .fire {
    width: 1rem;
    height: 1rem;
    background: #df6969;
    border-radius: 0% 100% 50% 50%/ 0% 50% 50% 100%;

    animation-name: fireFlick;
    animation-fill-mode: none;
    animation-duration: 0.1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-direction: alternate;
    animation: 0.1s ease 0 infinite alternate none fireFlick;
  }

  //fire-container_0~10
  & > div {
    .mobile & {
      margin: 0 0.6rem;
    }

    margin: 0 0.4rem;
    width: 1rem;
    height: 1rem;
  }
}

@for $i from 0 through $FIRE_NUM {
  .fire-container_#{$i} {
    transition-delay: #{1- ($i * 0.1)}s;
    transform: scale(0);
    transition-property: transform;
    transition-duration: 0.2s;
  }
}

@keyframes fireFlick {
  0% {
    transform: rotate(45deg) skew(6deg, 0deg);
  }

  100% {
    transform: rotate(45deg) skew(0deg, 6deg);
  }
}
